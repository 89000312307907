<!--<div class="overflow-x-auto">-->
<!--    <table class="min-w-full divide-y divide-gray-200 bg-white shadow-md rounded-lg">-->
<!--         <thead class="bg-gray-50">-->
<!--        <tr>-->
<!--            <th *ngFor="let title of titles" class="px-6 py-3 text-left text-sm font-medium text-gray-500">-->
<!--                {{ title.label }}-->
<!--            </th>-->
<!--        </tr>-->
<!--        </thead>-->

<!--        &lt;!&ndash; Table Body &ndash;&gt;-->
<!--        <tbody class="divide-y divide-gray-200">-->
<!--        <tr *ngFor="let row of data">-->
<!--            <td *ngFor="let title of titles" class="px-6 py-4 text-sm text-gray-700">-->
<!--                {{ row[title.key] }}-->
<!--            </td>-->
<!--        </tr>-->
<!--        </tbody>-->
<!--    </table>-->
<!--</div>-->

<div class="bg-white overflow-auto rounded-sm d-flex flex-column" dir="rtl">

    <div id="print-section">
        <div mat-dialog-content
             class="py-3 px-3 bold d-flex align-items-baseline justify-content-between success-dialog">
            <span class="mx-3">{{ title }}</span>
        </div>
        <div class="flex-grow-0 mx-3 pt-3 pb-4 px-3">

            <div class="my-3 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
<!--                    <div ngSwitch="{{ doctorSettlement?.status }}" class="main-color bold">-->
<!--                        <div *ngSwitchCase="0">ثبت شده</div>-->
<!--                        <div *ngSwitchCase="1">تایید شده توسط پزشک</div>-->
<!--                        <div *ngSwitchCase="2">جاری</div>-->
<!--                        <div *ngSwitchCase="3">انجام شده</div>-->
<!--                        <div *ngSwitchCase="4">رد شده</div>-->
<!--                    </div>-->
                </div>
                <div class="mx-1 text-black-50 bold">
                    <span>دوره: </span>
<!--                    <span>{{ doctorSettlement?.title }}</span>-->
                </div>
                <div class="text-black-50">
                    <span>شماره: </span>
<!--                    <span>{{ doctorSettlement?.refNumber | enNum }}</span>-->
                </div>
            </div>

            <table class="border w-100 overflow-auto bg-gray" >
                <tbody>
                <tr>
                    <td class="border px-3 py-2 main-color bold" style="border-color: rgba(60,91,108,0.7)">نام پزشک</td>
                    <td class="border px-3 py-2 " style="border-color: rgba(60,91,108,0.7)">
<!--                        {{ doctorSettlement?.firstName }} {{ doctorSettlement?.lastName }}-->
                    </td>
                </tr>
                <tr>
                    <td class="border px-3 py-2 main-color bold" style="border-color: rgba(60,91,108,0.7)">کد ملی</td>
                    <td class="border px-3 py-2 " style="border-color: rgba(60,91,108,0.7)">
<!--                        {{ doctorSettlement?.nationalCode ? (doctorSettlement?.nationalCode | enNum) : '-' }}-->
                    </td>
                </tr>
                <tr>
                    <td class="border px-3 py-2 main-color bold" style="border-color: rgba(60,91,108,0.7)">تاریخ واریز</td>
                    <td class="border px-3 py-2 " style="border-color: rgba(60,91,108,0.7)">
<!--                        {{ doctorSettlement?.paidAt ? (doctorSettlement?.paidAt | date | persianDate) : '-' }}-->
                    </td>
                </tr>
                <tr>
                    <td class="border px-3 py-2 main-color bold" style="border-color: rgba(60,91,108,0.7)">مبلغ به عدد</td>
                    <td class="border px-3 py-2 " style="border-color: rgba(60,91,108,0.7)">
<!--                        {{ doctorSettlement?.price | number | enNum}} ريال-->
                    </td>
                </tr>
                <tr>
                    <td class="border px-3 py-2 main-color bold" style="border-color: rgba(60,91,108,0.7)">مبلغ به حروف</td>
                    <td class="border px-3 py-2 " style="border-color: rgba(60,91,108,0.7)">
<!--                        {{ doctorSettlement?.price | numberToWords }}ريال-->
                    </td>
                </tr>
                <tr>
                    <td class="border px-3 py-2 main-color bold" style="border-color: rgba(60,91,108,0.7)">شماره کارت</td>
                    <td class="border px-3 py-2 " style="border-color: rgba(60,91,108,0.7)">
<!--                        {{ doctorSettlement?.cardNumber ? (doctorSettlement?.cardNumber | enNum) : '-' }}-->
                    </td>
                </tr>
                <tr>
                    <td class="border px-3 py-2 main-color bold" style="border-color: rgba(60,91,108,0.7)">شماره شبا</td>
                    <td class="border px-3 py-2 " style="border-color: rgba(60,91,108,0.7)">
<!--                        {{ doctorSettlement?.ibanNumber ? (doctorSettlement?.ibanNumber | enNum) : '-' }}-->
                    </td>
                </tr>
                <tr>
                    <td class="border px-3 py-2 main-color bold" style="border-color: rgba(60,91,108,0.7)">سهم پزشک</td>
                    <td class="border px-3 py-2 " style="border-color: rgba(60,91,108,0.7)">
<!--                        % {{ doctorSettlement?.contributionValue ? (doctorSettlement?.contributionValue | enNum) : '-' }}-->
                    </td>
                </tr>
                </tbody>
            </table>
        </div>
    </div>

    <div class="mx-3 pb-4 align-self-end d-flex">

        <button class="btn-red-link btn-sm d-flex align-items-center" (click)="cancel()">بستن</button>
        <button type="button" class="d-flex align-items-center btn btn-sm main-color" (click)="confirm()">
            <img src="assets/img/icons/download.svg" alt="arrow-down">
            <span class="px-1">دانلود</span>
        </button>

    </div>
</div>

