import {Component, Inject, Input, OnInit} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";

@Component({
  selector: 'app-table-pdf',
  templateUrl: './table-pdf.component.html',
  styleUrls: ['./table-pdf.component.css']
})
export class TablePdfComponent implements OnInit {
  // @Input() titles: { key: string; label: string }[] = [];
  // @Input() data: Record<string, any>[] = [];

  isPrintReady = false;
  isLoadingFactor = false;
  pdfData: any;
  dataTable: any;
  title: string = '';

  constructor(
      public dialog: MatDialogRef<TablePdfComponent>,
      @Inject(MAT_DIALOG_DATA) public data: any,
  ) { }

  ngOnInit(): void {
    if (this.data) {
      this.dataTable = this.data.tableData;
      this.title = this.data.title;
    }
  }

  cancel() {
    this.dialog.close(false);
  }

  confirm() {
    this.isLoadingFactor = true;
    this.isPrintReady = true;
    const printButton = document.getElementById('ngx-print-button') as HTMLElement;
    this.pdfData = this.dataTable;
    setTimeout(() => {
      if (printButton) {
        printButton.click();
      }
      this.isLoadingFactor = false;
    }, 300);
  }

}
