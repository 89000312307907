import { Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {HttpService} from 'src/app/shared/services/http.service';
import {SubSink} from "subsink";

@Component({
  selector: 'app-upload-file',
  templateUrl: './upload-file.component.html',
  styleUrls: ['./upload-file.component.css']
})
export class UploadFileComponent implements OnInit, OnDestroy {

  selectedFile: File | null = null;
  load = false;
  @Input() service = 'doctor';
  @Input() fileName = '';
  @Input() label = 'انتخاب فایل';
  @Output() loading = new EventEmitter<boolean>();
  @Output() error = new EventEmitter<string>();
  @Output() path = new EventEmitter<string>();
  private subs = new SubSink();
  constructor(private http: HttpService) { }

  ngOnInit(): void {}

  onFileSelected(event: any) {
    this.selectedFile = event.target.files[0];
  }

  upload() {
    if (!this.selectedFile) {
      this.error.emit('لطفا فایل را انتخاب کنید');
      return;
    }
    this.load = true;
    this.loading.emit(true);
    this.subs.add(this.http.postFile(this.selectedFile, this.service).subscribe({
      next: (res) => {
        this.load = false;
        this.path.emit(res.filename);
        this.loading.emit(false);
      },
      error: err => {
        this.load = false;
        this.error.emit('در آپلود تصویر مشکلی ایجاد شده است!');
        this.loading.emit(false);
      }
    }));
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
