<mat-form-field class="w-100" appearance="outline" >
    <mat-label>{{ label }}</mat-label>
    <textarea matInput [rows]="minRows" [formControl]="control" [placeholder]="placeholder"></textarea>

    <mat-hint *ngIf="control.touched && control.dirty" class="mt-3">
        <p class="form-errors" *ngIf="control?.errors?.['required']">
            {{ label }}
            الزامی است.
        </p>
        <p class="form-errors" *ngIf="control?.errors?.['pattern']">
            مقدار وارد شده برای
            {{ label }}
            معتبر نمی باشد.
        </p>
    </mat-hint>
</mat-form-field>
