<table mat-table [dataSource]="dataSource" class="mat-elevation-z8 d-none d-lg-table">
    <ng-container *ngFor="let column of columns; let i = index" [matColumnDef]="column.key">
        <ng-container *ngIf="column.type === 'select'">
            <th mat-header-cell *matHeaderCellDef>
                <mat-checkbox
                        (change)="toggleAllRows($event.checked)"
                        [checked]="isAllSelected()"
                        [indeterminate]="isIndeterminate()">
                </mat-checkbox>
            </th>
            <td mat-cell *matCellDef="let row">
                <mat-checkbox
                        (change)="toggleRowSelection(row)"
                        [checked]="isSelected(row)">
                </mat-checkbox>
            </td>
        </ng-container>
        <th mat-header-cell *matHeaderCellDef mat-sort-header>
            {{ column.label }}
        </th>
        <td mat-cell *matCellDef="let row; let rowIndex = index">
            <ng-container *ngIf="hasCustomTemplate(column.key); else defaultTemplate">
                <ng-container
                        *ngTemplateOutlet="getCustomTemplate(column.key)?.template; context: { $implicit: row }"
                ></ng-container>
            </ng-container>
            <ng-template #defaultTemplate>
                <ng-container *ngIf="column.type === 'ordinal'">
                    {{ convertEnglishNumberToPersianNumber.transform(rowIndex + 1) }}
                </ng-container>
                <ng-container *ngIf="column.type === 'date'">
                    {{ convertToPersianDatePipe.transform(row[column.key]) }}
                </ng-container>
                <ng-container *ngIf="column.type === 'currency'">
                    {{ convertEnglishNumberToPersianNumber.transform(decimalPipe.transform(row[column.key])) }}
                </ng-container>
                <ng-container *ngIf="column.type === 'button'" >
                    <button class="btn btn-outline-dark btn-sm mb-3 mx-1" type="button" (click)="onButtonClicked(column,row)">
                        {{ column.text || column.label }}
                    </button>
                </ng-container>
                <ng-container *ngIf="!isColumnOfPredefinedType(column)">
                    {{ convertEnglishNumberToPersianNumber.transform(row[column.key]) }}
                </ng-container>
            </ng-template>
        </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
</table>
<div class="d-lg-none">
    <div *ngFor="let row of dataSource" class="rounded-sm shadow-sm bg-light mb-3 p-3 ng-star-inserted">
        <div *ngFor="let column of columns" class="p-1">
            <span class="key">
            <ng-container *ngIf="column.type === 'select'">
                <mat-checkbox
                        (change)="toggleRowSelection(row)"
                        [checked]="isSelected(row)">
                </mat-checkbox>
            </ng-container>
            <ng-container *ngIf="column.type !== 'select'" >
                {{ column.label }}:
            </ng-container>
            </span>
            <span class="value">
        <!-- Check if custom template exists -->
        <ng-container *ngIf="hasCustomTemplate(column.key); else defaultTemplate">
          <ng-container
                  *ngTemplateOutlet="getCustomTemplate(column.key)?.template; context: { $implicit: row }"
          ></ng-container>
        </ng-container>
                <!-- Default Template -->
        <ng-template #defaultTemplate>
          <ng-container *ngIf="column.type === 'ordinal'">
            {{ convertEnglishNumberToPersianNumber.transform(dataSource.indexOf(row) + 1) }}
          </ng-container>
          <ng-container *ngIf="column.type === 'date'">
            {{ convertToPersianDatePipe.transform(row[column.key]) }}
          </ng-container>
          <ng-container *ngIf="column.type === 'currency'">
            {{ convertEnglishNumberToPersianNumber.transform(decimalPipe.transform(row[column.key])) }}
          </ng-container>
          <ng-container *ngIf="column.type === 'button'">
            <button
                    class="btn btn-outline-dark btn-sm mb-3 mx-1"
                    type="button"
                    (click)="onButtonClicked(column, row)"
            >
              {{ column.text || column.label }}
            </button>
          </ng-container>
          <ng-container *ngIf="!isColumnOfPredefinedType(column)">
            {{ convertEnglishNumberToPersianNumber.transform(row[column.key]) }}
          </ng-container>
        </ng-template>
      </span>
        </div>
    </div>
</div>
