import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {ToasterService} from '../shared/services/toaster.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private toaster: ToasterService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                let errorMessage = 'An unknown error occurred.';
                if (error.error?.errors?.DomainValidations?.length) {
                    errorMessage = error.error.errors.DomainValidations[0];
                    this.toaster.showError(errorMessage, 'Error');
                } else if (error.status) {
                    errorMessage = this.getErrorMessage(error.status);
                    this.toaster.showError(errorMessage, 'Error');
                } else {
                    this.toaster.showError('خطایی  رخ داده است.', 'Error');
                }

                console.error('Error:', error);
                return throwError(() => new Error(errorMessage));
            })
        );
    }

    private getErrorMessage(status: number): string {
        switch (status) {
            case 400:
                return 'Bad request. Please check your input.';
            case 401:
                return 'Unauthorized. Please log in.';
            case 500:
                return 'Server error. Please try again later.';
            case 404:
                return 'Not Found. Please try again later.';
            default:
                return 'An unexpected error occurred.';
        }
    }
}
