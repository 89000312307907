import { Component, Input, Output, OnInit, EventEmitter, TemplateRef, QueryList, ContentChildren, AfterContentInit } from '@angular/core';
import { EnNumPipe } from '../../pipes/enNum.pipe';
import { PersianDate } from '../../persian-date/persian-date.pipe';
import { DecimalPipe } from '@angular/common';
import { SelectionModel } from '@angular/cdk/collections';
import { Directive} from '@angular/core';

@Directive({
  selector: '[customColumn]'
})
export class CustomColumnDirective {
  @Input('customColumn') columnKey: string = '';

  constructor(public template: TemplateRef<any>) {}
}


@Component({
  selector: 'app-general-table',
  templateUrl: './general-table.component.html',
  styleUrls: ['./general-table.component.css'],
  providers: [
    EnNumPipe,
    PersianDate,
    DecimalPipe
  ]
})

export class GeneralTableComponent implements OnInit ,AfterContentInit {
  @Input() columns: { key: string; label: string; type?: string, text?: string }[] = [];
  @Input() dataSource: any[] = [];
  @Output() selectedRowsChange = new EventEmitter<any[]>();
  @Input() hasOrdinalColumn: string|boolean = false  ;
  @Input() hasSelectionColumn: string|boolean = false ;

  // Create SelectionModel instance
  selection = new SelectionModel<any>(true, []);
  @ContentChildren(CustomColumnDirective) customTemplates!: QueryList<CustomColumnDirective>;
  private _selectedRows: any[] = [];
  @Input()
  set selectedRows(value: any[]) {
    this._selectedRows = value;
    this.selection.clear();  // Clear the current selection
    value.forEach(row => this.selection.select(row));  // Select rows passed to input
  }
  get isShowOrdinalColumn(){
    return this.hasOrdinalColumn === true || this.hasOrdinalColumn === ''
  }
  get isShowSelectionColumn(){
    return this.hasSelectionColumn === true || this.hasSelectionColumn === ''
  }

  get selectedRows(): any[] {
    return this.selection.selected;
  }

  public predefinedTypes = [
    'button',
    'date',
    'currency',
    'ordinal',
    'select'
  ];

  isColumnOfPredefinedType(column): boolean {
    return this.predefinedTypes.includes(column.type);
  }

  constructor(
      public convertEnglishNumberToPersianNumber: EnNumPipe,
      public convertToPersianDatePipe: PersianDate,
      public decimalPipe: DecimalPipe
  ) { }

  // Get the column keys, dynamically including 'ordinal' if necessary
  get displayedColumns(): string[] {
    return this.columns.map(c => c.key);
  }

  // Handle when a button is clicked in a column
  onButtonClicked(column, row) {
    console.log(column);
    if (column.onClick && typeof column.onClick === 'function') {
      // Call the onclick method and pass the row as an argument if needed
      column.onClick(row);
    } else {
      console.log('No click handler defined for this column');
    }
  }

  // Toggle selection of a specific row
  toggleRowSelection(row: any) {
    this.selection.toggle(row); // Toggle the row selection using SelectionModel
    this.selectedRowsChange.emit(this.selectedRows);  // Emit the updated selection to parent
  }

  // Check if a row is selected
  isSelected(row: any): boolean {
    return this.selection.isSelected(row);
  }

  // Select all rows
  toggleAllRows(checked: boolean) {
    if (checked) {
      this.selection.select(...this.dataSource); // Select all rows
    } else {
      this.selection.clear(); // Deselect all rows
    }
    this.selectedRowsChange.emit(this.selectedRows);  // Emit updated selection
  }

  // Check if all rows are selected
  isAllSelected(): boolean {
    return this.selection.selected.length === this.dataSource.length;
  }

  // Check if some rows are selected (indeterminate state)
  isIndeterminate(): boolean {
    return this.selection.selected.length > 0 && this.selection.selected.length < this.dataSource.length;
  }
  ngAfterContentInit()
  {
    this.customTemplates.toArray().forEach((t: CustomColumnDirective) => {
      const templateKey = t.columnKey;
    });
  }
  getCustomTemplate (key:any){
     return this.customTemplates.toArray().reduce((acc, directive) => {
      acc[directive.columnKey] = directive;
      return acc;
    }, {})[key];
    // console.log(a)
    // return a?.phoneNumber
  }
  hasCustomTemplate(key:any){
    return !!this.customTemplates.toArray().reduce((acc, directive) => {
      acc[directive.columnKey] = directive;
      return acc;
    }, {})[key];
  }

  ngOnInit() {
    if(this.isShowOrdinalColumn) {
      this.columns.unshift({ key: 'ordianl', label: 'ردیف', type: 'ordinal' });
    }
    if(this.isShowSelectionColumn){
      this.columns.unshift({ key: 'select', label: '', type: 'select' });
    }
  }
}
