
<div class="auto-list-container w-full flex">


    <div class="chips-container">
        <!-- Chips list placed outside and above the input -->
        <mat-chip-list>
            <mat-chip *ngFor="let option of selectedItems" [removable]="true" (removed)="removeOption(option)">
                {{ option.name }}
                <mat-icon matChipRemove>cancel</mat-icon>
            </mat-chip>
        </mat-chip-list>
    </div>

    <!-- Input and Autocomplete -->
    <mat-form-field appearance="outline" class="w-100">
        <mat-label *ngIf="label">{{ label }}</mat-label>
        <input
                matInput
                [matAutocomplete]="auto"
                [formControl]="autocompleteControl"
                [placeholder]="label"
                (input)="filterItems()"
        />
        <mat-autocomplete #auto="matAutocomplete" (optionSelected)="selectOption($event)">
            <mat-option *ngFor="let filteredItem of filteredItems" [value]="filteredItem">
                {{ filteredItem.name }}
            </mat-option>
        </mat-autocomplete>
    </mat-form-field>


</div>
