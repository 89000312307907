import {Component, Input, OnInit} from '@angular/core';
import {FormControl} from "@angular/forms";

@Component({
  selector: 'app-textarea-mat',
  templateUrl: './textarea-mat.component.html',
  styleUrls: ['./textarea-mat.component.css']
})
export class TextareaMatComponent implements OnInit {

  @Input() label = '';
  @Input() placeholder = '';
  @Input() minRows: number = 3;
  @Input() control:FormControl = new FormControl('');

  constructor() { }

  ngOnInit(): void {
  }

}
