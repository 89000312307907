import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import {AuthGuard} from "src/app/shared/services/auth-guard.service";
import {NotFoundComponent} from "src/app/shared/not-found/not-found.component";
import {SiteLayoutComponent} from "src/app/layout/site-layout/site-layout.component";
import {LoginLayoutComponent} from "src/app/layout/login-layout/login-layout.component";

const routes: Routes = [

  {
    path: "",
    component: SiteLayoutComponent,
    children: [
      {
        path: 'dashboard',
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
        canActivate: [AuthGuard],
      },
      {
        path: '',
        pathMatch: "full",
        redirectTo: '/dashboard',
      },
      {
        path: 'doctors',
        loadChildren: () => import('./doctors/doctors.module').then(m => m.DoctorsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'finance',
        loadChildren: () => import('./finance/finance.module').then(m => m.FinanceModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'users',
        loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'consultant',
        loadChildren: () => import('./consultant/consultant.module').then(m => m.ConsultantModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'blogs',
        loadChildren: () => import('./blogs/blogs.module').then(m => m.BlogsModule),
        canActivate: [AuthGuard],
      },
      {
        path: 'settings',
        loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
        canActivate: [AuthGuard],
      },
    ],
  },

  {
    path: "login",
    component: LoginLayoutComponent,
    children: [
      {
        path: '',
        loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
      },
    ],
  },

  {
    path: '404',
    component: NotFoundComponent,
  },
  {
    path: '**',
    redirectTo: '/404'
  }


  // {
  //   path: 'login',
  //   loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule),
  // },
  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: 'doctors',
  //   loadChildren: () => import('./doctors/doctors.module').then(m => m.DoctorsModule),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: 'finance',
  //   loadChildren: () => import('./finance/finance.module').then(m => m.FinanceModule),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: 'users',
  //   loadChildren: () => import('./users/users.module').then(m => m.UsersModule),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: 'consultant',
  //   loadChildren: () => import('./consultant/consultant.module').then(m => m.ConsultantModule),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: 'blogs',
  //   loadChildren: () => import('./blogs/blogs.module').then(m => m.BlogsModule),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: 'settings',
  //   loadChildren: () => import('./settings/settings.module').then(m => m.SettingsModule),
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: '**',
  //   component: NotFoundComponent,
  // }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
