import {Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import {environment} from 'src/environments/environment';
import {FormControl} from "@angular/forms";
import {SubSink} from "subsink";
import {debounceTime, delay, distinctUntilChanged, switchMap} from "rxjs/operators";
import {of} from "rxjs";

@Component({
  selector: 'app-list-search',
  templateUrl: './list-search.component.html',
  styleUrls: ['./list-search.component.css']
})
export class ListSearchComponent implements OnInit, OnDestroy {

  @Input() service: string;
  @Input() controller: string;
  @Input() action: string;
  @Input() title: string = 'جستجو';
  @Output() valueChange = new EventEmitter<any>();
  @ViewChild('searchInput') input: ElementRef;

  value: any;
  loading: boolean;
  items: Array<any> = [];
  params = {PageIndex: 0, PageSize: 10};
  timeout: any;

  searchController = new FormControl('');
  private subs = new SubSink();

  constructor(private http: HttpClient) {
    this.subs.add(this.searchController.valueChanges.pipe(delay(1000)).subscribe(value => {
      if (this.controller !== 'Doctor') return;
      if (!value) this.clear();
      if (!value && this.params['Keyword']) delete this.params['Keyword'];
      else if (value) this.params['Keyword'] = value;
      this.getList();
    }));


    // this.subs.add(
    //     this.searchController.valueChanges.pipe(
    //         debounceTime(1000),
    //         switchMap(keyword => {
    //           if (keyword.trim().length > 0) {
    //             if (this.controller !== 'Doctor') return;
    //             if (!keyword) this.clear();
    //             if (!keyword && this.params['Keyword']) delete this.params['Keyword'];
    //             else if (keyword) this.params['Keyword'] = keyword;
    //             this.getList();
    //           }
    //           return of(null);
    //         })
    //     ).subscribe()
    // );

    // this.subs.add(
    //     this.searchController.valueChanges.pipe(
    //         debounceTime(1000),
    //         distinctUntilChanged(),
    //         switchMap(keyword => {
    //           if (keyword.trim().length > 0 && this.controller === 'Doctor') {
    //             if (!keyword) this.clear();
    //             if (!keyword && this.params['Keyword']) delete this.params['Keyword'];
    //             else if (keyword) this.params['Keyword'] = keyword;
    //           } else {
    //             delete this.params['Keyword'];
    //           }
    //           return of(null);
    //         })
    //     ).subscribe(() => {
    //       this.getList();
    //     })
    // );
  }

  ngOnInit(): void {
    this.getList();
  }

  getList() {
    this.loading = true;
    this.http.get(`${environment[this.service]}/${this.controller}/${this.action}`, {params: this.params})
      .subscribe(
        res => {
          this.items = res['resultItems'];
          this.loading = false;
        },
        err => {
          this.loading = false;
        }
      )
  }

  displayFn(item: any): string {
    return item && item.firstName && item.lastName? (item.firstName + ' ' + item.lastName) : '';
  }

  onKeySearch(event: any) {
    if (this.controller !== 'Doctor') return;

    clearTimeout(this.timeout);
    var $this = this;
    this.timeout = setTimeout(function () {
      if (event.keyCode !== 13) {
        if (!event.target.value) $this.clear();
        if (!event.target.value && $this.params['Keyword']) delete $this.params['Keyword'];
        else if (event.target.value) $this.params['Keyword'] = event.target.value;
        $this.getList();
      }
    }, 1500);
  }

  onSelectionChanged(event: any) {
    if (this.controller === 'Doctor') this.valueChange.emit(event.option.value.globalUserId);
    if (this.controller === 'Patient') this.valueChange.emit(event.option.value.userGlobalId);
  }

  clear() {
    this.value = null;
    this.valueChange.emit(null);
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
