import { Injectable } from "@angular/core";
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Observable } from "rxjs";
import { User, unconfirmedUser } from 'src/app/shared/models/user.model';
import { catchError, tap } from "rxjs/operators";
import { environment } from 'src/environments/environment';
import { HandleResponseService } from 'src/app/shared/services/response.service';
import { SignalrService } from 'src/app/shared/services/signalr.service';
import {
    PatientRegistrationRequestData,
    AuthResponseData,
    forgetPasswordData
} from 'src/app/shared/models/auth.model';

const httpOptions = {
    headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
    })
};

@Injectable({
    providedIn: 'root'
})

export class AuthService{

    private user$: BehaviorSubject<User> = new BehaviorSubject<User>(null);
    private tempUser$: BehaviorSubject<unconfirmedUser> = new BehaviorSubject<unconfirmedUser>(null);
    public user: Observable<User> = this.user$.asObservable();
    public isAuthenticated: BehaviorSubject<boolean>;
    public forgetPass: forgetPasswordData = {};

    constructor(
        private http: HttpClient,
        private router: Router,
        private response: HandleResponseService,
        private signalr: SignalrService)
        {}

    public get userValue(): User {
        return this.user$.value;
    }

    public get tempValue(): unconfirmedUser {
        return this.tempUser$.value;
    }

    signUpPatient(requestData: PatientRegistrationRequestData){
        return this.http.post<any>(`${environment.identityUrl}/user/RegisterPatient`, requestData)
        .pipe(
            catchError(this.response.handleError),
            tap(() => {
                this.tempUser$.next({
                    Mobile: requestData.Mobile,
                    Password: requestData.Password,
                    Name: requestData.Name,
                    LastName: requestData.LastName,
                    Email: requestData.Email
                })
            })
        )
    }

    handleVerification(token: string){
        return this.http.post<any>(`${environment.identityUrl}/user/VerifyMobileNumber `,
            {Mobile: this.tempValue.Mobile, Token: token}
        )
        .pipe(catchError(this.response.handleError))
    }

    resendVerificationToken(mobile: string){
        return this.http.post<any>(`${environment.identityUrl}/user/ResendMobileToken`, {Mobile: mobile})
        .pipe(catchError(this.response.handleError))
    }

    ForgotPasswordSendToken(mobile: string){
        return this.http.post<any>(`${environment.identityUrl}/user/ForgotPasswordSendToken`, {Mobile: mobile})
        .pipe(catchError(this.response.handleError))
    }

    ForgotPasswordChangePassword(data: any){
        return this.http.post<any>(`${environment.identityUrl}/user/ForgotPasswordChangePassword`,
            {
                Mobile: this.forgetPass.mobile,
                Token: this.forgetPass.token,
                Password: data.newPassword,
                ConfirmPassword: data.confirmNewPassword
            }
        )
        .pipe(catchError(this.response.handleError))
    }

    CheckUserSOSContract(data: any){
        return this.http.put<any>(`${environment.identityUrl}/user/CheckContract`,
            {
                nationalCode: data.nationalCode,
                membershipCode: data.membershipCode,
                username: this.userValue.preferred_username
            }
        )
        .pipe(catchError(this.response.handleError))
    }

    handleForgetPassVerification(token: string){
        this.forgetPass.token = token;
        return this.http.post<any>(`${environment.identityUrl}/user/VerifyMobileNumber `,
            {Mobile: this.forgetPass.mobile, Token: token}
        )
        .pipe(catchError(this.response.handleError))
    }

    ChangePassword(data){
        return this.http.post<any>(`${environment.identityUrl}/user/ChangePassword`,
            {
                ...data,
                Mobile: this.userValue.preferred_username
            }
        )
        .pipe(catchError(this.response.handleError))
    }

    setMobileNumber(mobile: string){
        this.forgetPass.mobile = mobile;
    }

    resetForgetPass(){
        this.forgetPass = {};
    }

    // signIn(username: string, password: string){
    //     const body = this.toUrlEncode({
    //         client_id: environment.client_id,
    //         client_secret: environment.client_secret,
    //         grant_type: 'password',
    //         username: username,
    //         password: password
    //     });
    //     return this.http.post<AuthResponseData>(`${environment.identityUrl}/connect/token`,
    //         body ,
    //         httpOptions
    //     ).pipe(
    //         catchError(this.response.handleError),
    //         tap((res: AuthResponseData) => {
    //             if (res.access_token){
    //                 const token = this.decodeJwtToken(res.access_token);
    //                 const user: User = {
    //                     ...this.createUserModel(token),
    //                     jwtToken: res.access_token,
    //                     refresh_token: res.refresh_token
    //                 };
    //                 this.user$.next(user);
    //                 this.startRefreshTokenTimer();
    //                 localStorage.setItem('sosUser', JSON.stringify(user));
    //                 // this.signalr.connect();
    //         }
    //     }));
    //
    // }

    signIn(username: string, password: string){
        const body = this.toUrlEncode({
            client_id: environment.client_id,
            client_secret: environment.client_secret,
            grant_type: 'password',
            username: username,
            password: password
        });
        return this.http.post<AuthResponseData>(`${environment.identityUrl}/connect/token`,
            body ,
            httpOptions
        ).pipe(
            catchError(this.response.handleError),
            tap((res: AuthResponseData) => {
                if (res.access_token){
                    const token = this.decodeJwtToken(res.access_token);
                    const user: User = {
                        ...this.createUserModel(token),
                        jwtToken: res.access_token,
                        refresh_token: res.refresh_token
                    };
                    this.user$.next(user);
                    this.startRefreshTokenTimer();
                    localStorage.setItem('sosUser', JSON.stringify(user));
                    // this.signalr.connect();
                }
            }));

    }

    registerDoctor(payload: any){
        return this.http.post<any>(`${environment.identityUrl}/user/RegisterDoctor`, payload)
        .pipe(catchError(this.response.handleError))
    }

    autoSignIn(){
        const user: User = JSON.parse(localStorage.getItem('sosUser'));
        if(!user) return;
        this.user$.next(user);
        this.startRefreshTokenTimer();
        // this.signalr.connect();
    }

    signOut(): void{
        this.stopRefreshTokenTimer();
        // this.signalr.disconnect();
        this.user$.next(null);
        localStorage.removeItem('sosUser');
        this.router.navigate(['/login']);
    }

    refreshToken() {
        const body = this.toUrlEncode({
            client_id: environment.client_id,
            client_secret: environment.client_secret,
            grant_type: 'refresh_token',
            refresh_token: this.userValue.refresh_token
        })

        return this.http.post<any>(`${environment.identityUrl}/connect/token`, body, httpOptions)
            .pipe(tap((resData) => {
                let newUser = this.user$.value;
                newUser.jwtToken = resData.access_token;
                newUser.refresh_token = resData.refresh_token;

                this.user$.next(newUser);
                this.startRefreshTokenTimer();
                localStorage.setItem('sosUser', JSON.stringify(newUser));
                // this.signalr.connect();
            }));
    }

    getUserInfo(){
        return this.http.get<User>(`${environment.identityUrl}/connect/userinfo`)
    }

    private toUrlEncode(obj){
        return obj = Object.keys(obj).map(k => encodeURIComponent(k) + '=' + encodeURIComponent(obj[k])).join('&');
    }

    private refreshTokenTimeout;

    private startRefreshTokenTimer() {
        const jwtToken = this.decodeJwtToken(this.userValue.jwtToken)
        const expires = new Date(jwtToken.exp * 1000);
        const timeout = expires.getTime() - Date.now() - (60 * 1000);
        if(timeout < 0) this.signOut();
        else this.refreshTokenTimeout = setTimeout(() => this.refreshToken().subscribe(), timeout);
    }

    private stopRefreshTokenTimer() {
        clearTimeout(this.refreshTokenTimeout);
    }

    private decodeJwtToken(token){
        let base64Url = token.split('.')[1];
        let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
        let jsonPayload = decodeURIComponent(atob(base64).split('').map(function(c) {
            return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        }).join(''));

        return JSON.parse(jsonPayload);
    }

    private createUserModel(token){
        const user: User = {
            sub: token.sub,
            name: token.name,
            last_name: token.last_name,
            preferred_username: token.preferred_username,
            email: token.email,
            role: (typeof token.role === "string")? [token.role] : token.role,
        };
        return user;
    }

    changePhoneNumber(data: any) {
        return this.http.put<any>(`${environment.identityUrl}/user/UpdatePhoneNumber`, data).pipe(catchError(this.response.handleError));
    }

    checkAcceptRules(params:any) {
        return this.http.get(`${environment.identityUrl}/User/CheckAcceptedRules`, {params: params});
    }

    postAcceptRules(data: any) {
        return this.http.put<any>(`${environment.identityUrl}/User/SetAcceptedRules`, {}, {params: data}).pipe(catchError(this.response.handleError));
    }

}
