import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'userType'
})
export class UserTypePipe implements PipeTransform {

  transform(value: number): string {
    switch (value) {
      case 0:
        return 'پزشک';
      case 1:
        return 'بیمار';
      case 2:
        return 'پزشک و بیمار';
      case 3:
        return 'ادمین';
      default:
        return 'نامشخص';
    }
  }

}
