import {Component, OnInit, Inject, OnDestroy} from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { HttpService } from 'src/app/shared/services/http.service';
import { environment } from 'src/environments/environment';
import {SubSink} from "subsink";

@Component({
  selector: 'app-image-upload',
  templateUrl: './image-upload.component.html',
  styleUrls: ['./image-upload.component.css']
})
export class ImageUploadComponent implements OnInit, OnDestroy {

  profileImageFile: any;
  imageUrl = '';
  imgChangeEvt: any;
  loading: boolean;
  error: string;
  private subs = new SubSink();
  constructor(
    private http: HttpService,
    public dialog: MatDialogRef<ImageUploadComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) { }

  get fileURL(): string {
    return `${environment.fileUrl}?objectName=`;
  }

  ngOnInit(): void {}

  getImageUrl() {
    this.loading = true;
    this.subs.add(this.http.getFile(this.data['imgUrl']).subscribe(res => {
      console.log(res)
    }));
  }

  onFileChange(event: any) {
    this.imgChangeEvt = event;
  }

  changeProfile(event: any) {
    this.profileImageFile = event;
  }

  changeErrorMessage(message: string) {
    this.error = message;
    setTimeout(function () { this.error = null }.bind(this), 5000);
  }

  save() {
    this.loading = true;
    this.subs.add(this.http.postFile(this.profileImageFile, this.data.service).subscribe(
        res => {
          this.loading = false;
          this.dialog.close({type: 'Save', data: res.filename});
        },
        err => {
          this.loading = false;
          // this.error = 'در آپلود تصویر مشکلی ایجاد شده است!';
          // setTimeout(function () { this.error = null }.bind(this), 5000);
        }
      ));
  }

  cancel() {
    this.dialog.close({type: 'Cancel'});
  }

  onRemoveFile() {
    this.profileImageFile = null;
    this.imgChangeEvt = null;
    this.dialog.close({type: 'Remove', data: null});
  }

  ngOnDestroy() {
    this.subs.unsubscribe();
  }

}
