<div class="py-3 px-2">
  <div mat-dialog-title class="d-flex align-items-center justify-content-between"></div>

  <div mat-dialog-content class="m-1">
    <h4>{{message}}</h4>
  </div>
  <div mat-dialog-actions>
    <button class="btn btn-secondary m-1" (click)="cancel()">انصراف</button>
    <button class="btn btn-primary m-1" (click)="confirm()" cdkFocusInitial>تایید</button>
  </div>

</div>
