import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'requestStatus'
})
export class RequestStatusPipe implements PipeTransform {

  transform(value: number): string {
    switch (value) {
      case 0:
        return 'در انتظار بررسی';
      case 1:
        return 'در حال بررسی';
      case 2:
        return 'بسته شده';
      default:
        return 'نامشخص';
    }
  }

}
