<div class="form-group">
<!--  <input-->
<!--    #searchInput-->
<!--    type="text"-->
<!--    [placeholder]="title"-->
<!--    [(ngModel)]="value"-->
<!--    [matAutocomplete]="auto"-->
<!--    [disabled]="loading"-->
<!--    class="form-control"-->
<!--    (keyup)="onKeySearch($event)">-->
  <input
      #searchInput
      type="text"
      [placeholder]="title"
      [matAutocomplete]="auto"
      [formControl]="searchController"
      [disabled]="loading"
      class="form-control">
  <button *ngIf="value" matSuffix mat-icon-button aria-label="Clear" (click)="clear()">
    <mat-icon>close</mat-icon>
  </button>
  <mat-autocomplete #auto="matAutocomplete" [displayWith]="displayFn" (optionSelected)="onSelectionChanged($event)">
    <mat-option *ngFor="let item of items" [value]="item">
      {{ item.firstName }}&nbsp;{{ item.lastName }}
    </mat-option>
  </mat-autocomplete>
</div>
