<div class="d-flex align-items-center justify-content-between">
  <div class="d-flex align-items-center">
    <input type="file" id="fileInput" (change)="onFileSelected($event)" style="position: absolute; display: none;" #fileInput />
    <button type="button" [disabled]="load" class="btn book-btn" (click)="fileInput.click()" >{{ label }}</button>
    <span class="mx-3" *ngIf="selectedFile && selectedFile?.name" >{{ selectedFile?.name }}</span>
    <span class="mx-3" *ngIf="!selectedFile && fileName !== ''" >{{ fileName }}</span>
  </div>

  <button type="button" [disabled]="load || !selectedFile" class="btn btn-outline-success" (click)="upload()">ثبت</button>
</div>
