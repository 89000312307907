import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
  
@Injectable({
  providedIn: 'root'
})
export class ToasterService {
  
  constructor(private toastr: ToastrService) { }
  
  showSuccess(message, title){
      this.toastr.success(message, title, {timeOut: 6000, positionClass: 'toast-top-center', toastClass: 'ngx-toastr w-50'});
  }
  
  showError(message, title){
      this.toastr.error(message, title, {timeOut: 6000, positionClass: 'toast-top-center', toastClass: 'ngx-toastr w-50'});
  }
  
  showInfo(message, title){
      this.toastr.info(message, title, {timeOut: 6000, positionClass: 'toast-top-center', toastClass: 'ngx-toastr w-50'});
  }
  
  showWarning(message, title){
      this.toastr.warning(message, title, {timeOut: 6000, positionClass: 'toast-top-center', toastClass: 'ngx-toastr w-50'});
  }
  
}
