import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from "@angular/router";
import {AuthService} from "../../shared/services/auth.service";
import {ConvertNumberService} from "../../shared/services/convert-number.service";
import {MatDialog} from "@angular/material/dialog";
import {AcceptRulesComponent} from "../accept-rules/accept-rules.component";

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit {

  error: string;
  loading: boolean;
  show: boolean;
  model = {
    Mobile: '',
    Password: ''
  };
  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private numberService: ConvertNumberService,
    private dialog: MatDialog,
  ) { }

  ngOnInit(): void {}

  onSubmit(): void {
    this.loading = true;
    this.authService.signIn(this.model.Mobile, this.model.Password)
      .subscribe(
        res => {
          // this.checkAcceptRules();
          this.loading = false;
          this.router.navigateByUrl('/dashboard');
        },
        err => {
          (err.status === 400) ? this.error = 'نام کاربری یا رمز عبور اشتباه است!' : this.error = 'خطایی رخ داده است دوباره تلاش کنید';
          setTimeout(function(): void { this.error = null; }.bind(this), 5000);
          this.loading = false;
        }
      );
  }

  checkAcceptRules() {
    let data = {
      phoneNumber: this.model.Mobile
    };
    this.authService.checkAcceptRules(data).subscribe(
      res => {
        if (res) {
          this.loading = false;
          this.router.navigateByUrl('/dashboard');
        } else {
          this.dialog.open(AcceptRulesComponent, {
            height: '90%',
            width: '70%',
            direction: 'rtl',
            panelClass: 'full-width-dialog p-3',
            data: false,
            disableClose: true,
            autoFocus: false,
          }).afterClosed().subscribe(
            confirmation => {
              if (confirmation) {
                this.authService.postAcceptRules(data).subscribe(
                  res => {
                    this.loading = false;
                    this.router.navigateByUrl('/');
                  },
                  err => {
                    this.error = 'خطایی رخ داده است دوباره تلاش کنید';
                    this.loading = false;
                  }
                );
              }
            }
          );
        }
      },
      err => {
        this.error = 'خطایی رخ داده است دوباره تلاش کنید';
        this.loading = false;
      }
    );
  }

  onRegisterBtnClicked() {
    this.router.navigate(['../signup'], { relativeTo: this.activatedRoute })
  }

  onForgetPasswordClicked() {
    this.router.navigate(['../forget-password'], { relativeTo: this.activatedRoute })
  }

  onShowPasswordClicked() {
    if (!this.model['Password']) return
    this.show = !this.show
  }

  mobileChange(value: any) {
    this.model.Mobile = this.numberService.convertPersianNumbersToEnglish(value)
  }

}
