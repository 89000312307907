<div class="form-group" *ngIf="!isMat">
  <div class="form-control date-control" [ngClass]="{'is-invalid': invalid}" [ngStyle]="{'background-color' : readonly? '#e9ecef' : ''}">
    <input
      [value]="value"
      [matDatepicker]="picker1"
      [placeholder]="placeHolder"
      [disabled]="readonly"
      [matDatepickerFilter]="myFilter"
      (dateChange)="onChange($event)">
    <mat-datepicker-toggle matSuffix [for]="picker1"></mat-datepicker-toggle>
    <mat-datepicker #picker1></mat-datepicker>
  </div>
  <div class="invalid-feedback">
    تاریخ الزامی است.
  </div>
</div>

<div class="w-100" *ngIf="isMat">
  <mat-form-field appearance="outline" class="w-100">
    <mat-label *ngIf="label">{{ label }}</mat-label>
    <input
        [value]="value"
        matInput
        [matDatepicker]="picker2"
        [matDatepickerFilter]="myFilter"
        [placeholder]="placeHolder"
        [formControl]="control"
        (dateChange)="onChange($event)">
    <mat-datepicker-toggle matSuffix [for]="picker2"></mat-datepicker-toggle>
    <mat-datepicker #picker2></mat-datepicker>

    <mat-hint *ngIf="control.touched">
      <p class="form-errors" *ngIf="control?.errors?.['required']">
        {{ label }}
        الزامی است.
      </p>
      <p class="form-errors" *ngIf="control?.errors?.['minlength']">
        {{ label }}
        باید دارای حداقل
        {{ control.errors?.['minlength'].requiredLength  }}
        حرف باشد. شما
        {{ control.errors?.['minlength'].actualLength }}
        حرف وارد کرده اید.
      </p>
      <p class="form-errors" *ngIf="control?.errors?.['maxlength']">
        {{ label }}
        نباید بیشتر از
        {{ control.errors?.['maxlength'].requiredLength  }}
        حرف باشد. شما
        {{ control.errors?.['maxlength'].actualLength }}
        حرف وارد کرده اید.
      </p>
      <p class="form-errors" *ngIf="control?.errors?.['pattern']">
        {{ label }}
        وارد شده صحیح نمی باشد.
      </p>
      <p class="form-errors" *ngIf="control?.errors?.['min']">
        {{ label }}
        کمتر از حداقل مقدار است.
      </p>
      <p class="form-errors" *ngIf="control?.errors?.['max']">
        {{ label }}
        بیشتر از حداکثر مقدار است.
      </p>
      <p class="form-errors" *ngIf="control?.errors?.['email']">
        {{ label }}
        وارد شده صحیح نمی باشد.
      </p>
    </mat-hint>

  </mat-form-field>
</div>
