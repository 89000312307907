import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import * as moment from 'moment';
import { MatDatepickerInputEvent } from '@angular/material/datepicker';
import { JalaliMomentDateAdapter } from 'src/app/core/jalali-moment-date-adapter';

@Component({
  selector: 'app-date-picker',
  templateUrl: './date-picker.component.html',
  styleUrls: ['./date-picker.component.css']
})
export class DatePickerComponent implements OnInit {

  @Input() value: string;
  @Input() placeHolder: string = '';
  @Input() dateControl: FormGroup;
  @Input() birthDate: Date;
  @Input() invalid: boolean;
  @Input() readonly: boolean;
  @Output() birthDateChange = new EventEmitter<any>();
  @Output() valueChange = new EventEmitter<any>();
  @Input() control = new FormControl('');
  @Input() label = '';
  @Input() isMat = true;
  @Input() minDate: any = moment('1920-10-02', 'YYYY-MM-DD');
  adapter: JalaliMomentDateAdapter;
  startDate = moment('2017-01-01', 'YYYY-MM-DD');
  // minDate = moment('2017-10-02', 'YYYY-MM-DD');
  maxDate = moment('1396-07-29', 'jYYYY-jMM-jDD');
  jsonDate = '2017-10-19T12:19:48.817';
  weeksInJalali = [
    { enName: 'Saturday', faName: 'شنبه', faAbbr: 'ش', order: 0 },
    { enName: 'Sunday', faName: 'یکشنبه', faAbbr: 'ی', order: 1 },
    { enName: 'Monday', faName: 'دوشنبه', faAbbr: 'د', order: 2 },
    { enName: 'Tuesday', faName: 'سه شنبه', faAbbr: 'س', order: 3 },
    { enName: 'Wednesday', faName: 'چهارشنبه', faAbbr: 'چ', order: 4 },
    { enName: 'Thursday', faName: 'پنجشنبه', faAbbr: 'پ', order: 5 },
    { enName: 'Friday', faName: 'جمعه', faAbbr: 'ج', order: 6 }
  ];

  monthsInJalali = [
    { faName: 'فروردین', order: 0 },
    { faName: 'اردیبهشت', order: 1 },
    { faName: 'خرداد', order: 2 },
    { faName: 'تیر', order: 3 },
    { faName: 'مرداد', order: 4 },
    { faName: 'شهریور', order: 5 },
    { faName: 'مهر', order: 6 },
    { faName: 'آبان', order: 7 },
    { faName: 'آذر', order: 8 },
    { faName: 'دی', order: 9 },
    { faName: 'بهمن', order: 10 },
    { faName: 'اسفند', order: 11 }
  ];

  constructor() { }

  ngOnInit(): void {
    this.adapter = new JalaliMomentDateAdapter();
  }

  onInput(event: MatDatepickerInputEvent<moment.Moment>) {
    console.log('onInput: ', event.value);
  }

  onChange(event: MatDatepickerInputEvent<moment.Moment>) {
    if(!event.value) {
      this.valueChange.emit(null)
      return
    }
    this.birthDateChange.emit(moment(event.value).format('jYYYY-jMM-jDD HH:mm'));
    this.valueChange.emit(moment(event.value).format('YYYY-MM-DD HH:mm:ss'));
  }

  myFilter = (d: any): boolean => {
    if (!d || !this.minDate) {
      return true;
    }

    const selectedDate = (d instanceof Date) ? d : new Date(d);
    const minDate = (this.minDate instanceof Date) ? this.minDate : new Date(this.minDate);

    return selectedDate >= minDate;
  };

}
