import { Injectable } from '@angular/core';
import {
    HttpEvent,
    HttpHandler,
    HttpInterceptor,
    HttpRequest,
    HttpErrorResponse,
} from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import {ToasterService} from '../shared/services/toaster.service';

@Injectable()
export class HttpErrorInterceptor implements HttpInterceptor {
    constructor(private toaster: ToasterService) {}

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(req).pipe(
            catchError((error: HttpErrorResponse) => {
                let errorMessage = 'خطایی رخ داده است لطفا مجددا تلاش کنید.';
                if (error.error?.errors) {
                    errorMessage = '';
                    for (const errorMessageKey in error.error?.errors) {
                        errorMessage += error.error?.errors[errorMessageKey][0];
                    }
                    this.toaster.showError(errorMessage, 'خطا!');
                } else if (error?.status) {
                    errorMessage = this.getErrorMessage(error.status);
                    this.toaster.showError(errorMessage, 'خطا!');
                } else {
                    this.toaster.showError('خطایی  رخ داده است.', 'خطا!');
                }
                return throwError(() => new Error(errorMessage));
            })
        );
        // return next.handle(req).pipe(
        //     catchError((error: HttpErrorResponse) => {
        //         let errorMessage = 'خطایی رخ داده است لطفا مجددا تلاش کنید.';
        //         if (error.error?.errors) {
        //             errorMessage = '';
        //             for (const errorMessageKey in error.error?.errors) {
        //                 errorMessage += error.error?.errors[errorMessageKey][0];
        //             }
        //             this.toaster.showError(errorMessage, 'خطا!');
        //         }
        //         if (error.error?.errors?.DomainValidations?.length) {
        //             errorMessage = error.error.errors.DomainValidations[0];
        //             this.toaster.showError(errorMessage, 'خطا!');
        //         } else if (error?.status) {
        //             errorMessage = this.getErrorMessage(error.status);
        //             this.toaster.showError(errorMessage, 'خطا!');
        //         } else {
        //             this.toaster.showError('خطایی  رخ داده است.', 'خطا!');
        //         }
        //         return throwError(() => new Error(errorMessage));
        //     })
        // );
    }

    private getErrorMessage(status: number): string {
        switch (status) {
            case 400:
                return 'لطفا از صحت اطلاعات ورودی اطمینان حاصل کنید و مجددا تلاش کنید.';
            case 401:
                return ' شما اجازه دسترسی به این صفحه رو ندارید. لطفا وارد حساب کاربری خود شوید و دوباره تلاش کنید.';
            case 500:
                return ' لطفا دقایقی دیگر دوباره تلاش کنید.';
            case 404:
                return 'خطایی رخ داده است. لطفا دوباره تلاش کنید.';
            default:
                return 'خطایی رخ داده است! لطفا دوباره تلاش کنید.';
        }
    }

    // private getErrorMessage(status: number): string {
    //     switch (status) {
    //         case 400:
    //             return 'Bad request. Please check your input.';
    //         case 401:
    //             return 'Unauthorized. Please log in.';
    //         case 500:
    //             return 'Server error. Please try again later.';
    //         case 404:
    //             return 'Not Found. Please try again later.';
    //         default:
    //             return 'An unexpected error occurred.';
    //     }
    // }
}
