import { Component, Input, OnInit } from '@angular/core';
import { FormControl } from '@angular/forms';

interface ItemModel {
  id: number;
  name: string;
}

@Component({
  selector: 'app-select-with-chips',
  templateUrl: './select-with-chips.component.html',
  styleUrls: ['./select-with-chips.component.css']
})
export class SelectWithChipsComponent implements OnInit {
  @Input() control: FormControl = new FormControl([]);
  @Input() label = '';
  @Input() items: ItemModel[] = [];
  @Input() selectedItems: ItemModel[] = [];

  autocompleteControl = new FormControl('');
  filteredItems: ItemModel[] = [];

  ngOnInit(): void {
    this.filteredItems = [...this.items];
    this.autocompleteControl.valueChanges.subscribe(() => {
      this.filterItems();
    });
  }

  filterItems(): void {
    const value = this.autocompleteControl.value?.toLowerCase() || '';
    this.filteredItems = this.items.filter(
        (item) =>
            item.name.toLowerCase().includes(value) &&
            !this.selectedItems.some((selected) => selected.id === item.id)
    );
  }

  selectOption(event: any): void {
    const selectedItem = event.option.value;
    this.selectedItems.push(selectedItem);
    this.control.setValue(this.selectedItems.map((item) => item.id));
    this.autocompleteControl.setValue(''); // Clear the input
    this.filterItems();
  }

  removeOption(option: ItemModel): void {
    const index = this.selectedItems.findIndex((item) => item.id === option.id);
    if (index >= 0) {
      this.selectedItems.splice(index, 1);
      this.control.setValue(this.selectedItems.map((item) => item.id));
      this.filterItems();
    }
  }
}
