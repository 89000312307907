import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'requestTag'
})
export class RequestTagPipe implements PipeTransform {

  transform(value: number): string {
    switch (value) {
      case 0:
        return 'ثبت نام و اطلاعات پایه';
      case 1:
        return 'اطلاعات شخصی';
      case 2:
        return 'اطلاعات تخصص و شماره نظام پزشکی';
      case 3:
        return 'اطلاعات پروانه طبابت';
      case 4:
        return 'تصویر پروفایل';
      case 5:
        return 'ویدئو معرفی در پروفایل';
      case 6:
        return 'اطلاعات عمومی پزشک';
      case 7:
        return 'اطلاعات حساب کاربری';
      case 8:
        return 'تصویر امضاء';
      case 9:
        return 'تصویر مهر';
      case 10:
        return 'تمدید قرارداد';
      default:
        return 'نامشخص';
    }
  }

}
