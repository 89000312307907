import {Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {BehaviorSubject, Observable, throwError} from 'rxjs';
import {environment} from 'src/environments/environment';

const httpOptions = {
  headers: new HttpHeaders({
    'Content-Type': 'multipart/form-data',
    'Access-Control-Allow-Origin': '*'
  })
};

@Injectable({
  providedIn: 'root'
})

export class HttpService {

  constructor(private http: HttpClient) {
  }

  // Doctor API request...
  postData(controller: string, body: any): Observable<any> {
    const _url = `${environment.apiUrl}/${controller}`;
    return this.http.post(_url, body);
  }

  postDataWithAction(controller: string, action: string, body): Observable<any> {
    const _url = `${environment.apiUrl}/${controller}/${action}`;
    return this.http.post(_url, body);
  }

  getData(controller: string, action?: string): Observable<any> {
    let _url = '';
    _url = action ? `${environment.apiUrl}/${controller}/${action}` : `${environment.apiUrl}/${controller}`;
    return this.http.get(_url);
  }

  getDataWithParam(controller: string, action: string, params: any): Observable<any> {
    const _url = `${environment.apiUrl}/${controller}/${action}`;
    return this.http.get(_url, {params});
  }

  getSimpleDataWithParam(controller: string, params: any): Observable<any> {
    const _url = `${environment.apiUrl}/${controller}`;
    return this.http.get(_url, {params});
  }

  deleteData(controller: string, params: any): Observable<any> {
    const _url = `${environment.apiUrl}/${controller}`;
    return this.http.delete(_url, {params});
  }

  deleteDataWithAction(controller: string, action: string, params: any): Observable<any> {
    const _url = `${environment.apiUrl}/${controller}/${action}`;
    return this.http.delete(_url, {params});
  }

  editData(controller: string, body: any): Observable<any> {
    const _url = `${environment.apiUrl}/${controller}`;
    return this.http.put(_url, body);
  }

  editDataWithAction(controller: string, action: string, body: any): Observable<any> {
    const _url = `${environment.apiUrl}/${controller}/${action}`;
    return this.http.put(_url, body);
  }

  editDataWithParam(controller: string, action: string, id: number): Observable<any> {
    const _url = `${environment.apiUrl}/${controller}/${action}?id=${id}`;
    return this.http.put(_url, null);
  }

  postFile(fileToUpload: any, section: string) {
    const formData: FormData = new FormData();
    formData.append('data', fileToUpload);
    formData.append('section', section);
    return this.http.post<any>(`${environment.fileUrl}`, formData);
  }

  retrieveFile(params: any) {
    const _url = `${environment.fileUrl}`;
    return this.http.get(_url, {params});
  }

  getFile(objectName: string) {
    const _url = `${environment.fileUrl}?objectName=${objectName}`;
    return this.http.get(_url );
  }

  // Patient api request...
  getPatientData(controller: string, params?: any): Observable<any> {
    const _url = `${environment.patientUrl}/${controller}`;
    if (params) {
      return this.http.get(_url, {params});
    } else {
      return this.http.get(_url);
    }
  }

  getPatientDataWithAction(controller: string, action: string, params?: any): Observable<any> {
    const _url = `${environment.patientUrl}/${controller}/${action}`;
    if (params) {
      return this.http.get(_url, {params});
    } else {
      return this.http.get(_url);
    }
  }

  updatePatinetData(controller: string, body: any): Observable<any> {
    const _url = `${environment.patientUrl}/${controller}`;
    return this.http.put(_url, body);
  }

  updatePatientDataWithAction(controller: string, action: string, body: any): Observable<any> {
    const _url = `${environment.patientUrl}/${controller}/${action}`;
    return this.http.put(_url, body);
  }

  updatePatinetDataWithParam(controller: string, action: string, id: number): Observable<any> {
    const _url = `${environment.patientUrl}/${controller}/${action}?id=${id}`;
    return this.http.put(_url, null);
  }

  getPatientDataWithUrl (url: string): Observable<any> {
    return this.http.get(`${environment.patientUrl}/${url}`);
  }

  postPatientData(controller: string, body: any): Observable<any> {
    const _url = `${environment.patientUrl}/${controller}`;
    return this.http.post(_url, body);
  }

  deletePatientData(controller: string, params: any): Observable<any> {
    const _url = `${environment.patientUrl}/${controller}`;
    return this.http.delete(_url, {params});
  }

  // consultant api request
  postConsultantData(controller: string, body: any): Observable<any> {
    const _url = `${environment.consultantUrl}/${controller}`;
    return this.http.post(_url, body);
  }

  postConsultantDataWithAction(controller: string, action: string, body: any): Observable<any> {
    const _url = `${environment.consultantUrl}/${controller}/${action}`;
    return this.http.post(_url, body);
  }

  getConsultantDataWithAction(controller: string, action: string, params?: any): Observable<any> {
    const _url = `${environment.consultantUrl}/${controller}/${action}`;
    return params ? this.http.get(_url, {params}) : this.http.get(_url);
  }

  getConsultantData(controller: string, params: any) {
    const _url = `${environment.consultantUrl}/${controller}`;
    return this.http.get(_url, {params});
  }

  // schedule api request
  getScheduleData(controller: string, action?: string, params?: any): Observable<any> {
    const _url = action ? `${environment.scheduleUrl}/${controller}/${action}` : `${environment.scheduleUrl}/${controller}`;
    return params ? this.http.get(_url, {params}) : this.http.get(_url);
  }

  postScheduleData(controller: string, body: any): Observable<any> {
    const _url = `${environment.scheduleUrl}/${controller}`;
    return this.http.post(_url, body);
  }

  postScheduleDataWithAction(controller: string, action: string, body: any): Observable<any> {
    const _url = `${environment.scheduleUrl}/${controller}/${action}`;
    return this.http.post(_url, body);
  }

  postScheduleDataWithParams(controller: string, action: string, params: any): Observable<any> {
    const _url = `${environment.scheduleUrl}/${controller}/${action}`;
    return this.http.post(_url, null, {params});
  }

  updateScheduleData(controller: string, body: any): Observable<any> {
    const _url = `${environment.scheduleUrl}/${controller}`;
    return this.http.put(_url, body);
  }

  deleteSecheduleData(controller: string, body: any): Observable<any> {
    const _url = `${environment.scheduleUrl}/${controller}`;
    return this.http.request('delete', _url, {body});
  }

  deleteScheduleDataWithID(controller: string, params: any): Observable<any> {
    const _url = `${environment.scheduleUrl}/${controller}`;
    return this.http.delete(_url, {params});
  }

  // wallet api request
  getWalletData(controller: string, action?: string, params?: any): Observable<any> {
    const _url = action ? `${environment.walletUrl}/${controller}/${action}` : `${environment.walletUrl}/${controller}`;
    return params ? this.http.get(_url, {params}) : this.http.get(_url);
  }

  postWalletData(controller: string, action: string, body: any): Observable<any> {
    const _url = `${environment.walletUrl}/${controller}/${action}`;
    return this.http.post(_url, body);
  }

  deleteWalletDataWithID(controller: string, action: string,  params: any): Observable<any> {
    const _url = `${environment.walletUrl}/${controller}/${action}`;
    return this.http.delete(_url, {params});
  }

  updateWalletData(controller: string, action: string, body: any): Observable<any> {
    const _url = `${environment.walletUrl}/${controller}/${action}`;
    return this.http.put(_url, body);
  }

  // shared kernel Api's
  sendEmail(controller: string, body: any): Observable<any> {
    const _url = `${environment.sharedUrl}/${controller}`;
    return this.http.post(_url, body);
  }

}

